
import { License } from '@/models';
import { HomeRoute, LicenseEditRoute } from '@/router';
import { ApiService, LoaderService } from '@/services';
import { defineComponent, onMounted, ref } from 'vue';
import { RouteLocationRaw, useRouter } from 'vue-router';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useToast } from 'primevue/usetoast';

export default defineComponent({
	components: { DataTable, Column },
	setup() {
		const licenses = ref<License[]>([]);
		const loadToken = LoaderService.invoke();
		const router = useRouter();
		const toast = useToast();

		onMounted(async () => {
			try {
				licenses.value = await ApiService.get<License[]>('/licenses/all');
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to load licenses. Try again later.',
					life: 3000
				});

				router.push({ name: HomeRoute });
			} finally {
				LoaderService.clear(loadToken);
			}
		});

		const editRoute = (licenseId: number): RouteLocationRaw => ({
			name: LicenseEditRoute,
			params: { licenseId }
		});

		return { licenses, editRoute };
	}
});
